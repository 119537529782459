
// ---
// Variables
// ---

// ---
// Font family
// ---
$base-font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
// $base-font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", Meiryo, sans-serif;

// ---
// Colors
// ---
$base-font-color: #333333;
$bg-color: #ffffff;
$link-color:     #333333;
$link-hover:     lighten($link-color, 20%);

/*Key color*/
$color-note: #ff2b01;
$color-title: #fdfdfd;
$color-white: #fff;
$color-green: #319649;
$color-999: #999;
$color-666: #666666;
$color-525: #525252;
$color-444: #444;
$color-3d3: #3d3d3d;
$color-333: #333;


// ---
// Scale
// ---
$base-value:      16;
$base-value-second:      14;
$base-unit:      1%;
$base-size:      1rem;
// $base-font-size:      ($base-value)/0.16 + $base-unit;
// $base-font-size:      ($base-value)/0.16;
$base-font-size: 62.5%;
$base-font-size-second:      ($base-value-second)/0.16 + $base-unit;
$base-space:     (4/$base-value)*$base-size;

$max-contents-width:      1170px;
$min-contents-width:      960px;

$gheader-value:      72;
$gheader-height:      ($gheader-value / $base-value)*$base-size;
//$gheader-height-top:      (($gheader-value+36.5) / $base-value)*$base-size;

$size-xs:      0.6*$base-size;
$size-ms:      0.8*$base-size;
$size-s:      (12/$base-value)*$base-size;
$size-l:      1.2*$base-size;
$size-ml:      1.6*$base-size;
$size-xl:      2*$base-size;

$size-h1:        (26.5/$base-value)*$base-size;
$size-h2:        (36/$base-value)*$base-size;
$size-h3:        (24/$base-value)*$base-size;
//$size-h4:        (16/$base-value)*$base-size;
//$size-h5:        $base-size;

// ---
// Screen sizes
// ---
$screen-xs:     321px;
$screen-sm:     414px;
$screen-md:     769px;
$screen-lg:     $min-contents-width;
$screen-xl:     $max-contents-width;

