// ---
// Common Styles
// ---

//レイアウト
.row {
  max-width: $min-contents-width;
  width: 100%;
  margin: 0 auto;
  @include clearfix;
  @include paddingRem( 0, 20, 0, 20);
  @include bp(md) {
    padding: 0;
  }
  .row {
    width: 100%;
    padding: 0;
  }
}

//フォームパーツ
form {
  dt {
    margin-bottom: 12px;
  }
  dd:not(:last-of-type) {
    margin-bottom: 12px;
  }
  input.input_text, textarea {
    padding: 0 20px;
    border-radius: 4px;
    border: 1px solid $color-444;
    outline: 0;
    background-color: transparent;
    width: 100%;
    @include all-trans;
    &:focus {
      border-color: $color-green;
    }
  }
  input.input_text {
    height: 47px;
  } 
}

.bold {
  font-weight: bold !important;
}
