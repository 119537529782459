// ---
// Buttons
// ---

.btn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  max-width: (340rem/$base-value);
  width: 100%;
  @include paddingRem(14,14,14,14);
  @include gradient;
  border: none;
  border-radius: (4rem / $base-value-second);
  text-decoration: none;
  text-align: center;
  font-size: 1.143rem;
  @include em;
  color: $color-white;
  line-height: 1;
  vertical-align: middle;
  @include all-trans(all,.25s,ease-in);
  cursor: pointer;
  &:hover, &:active {
    box-shadow:0px 0px 16px 3px rgba(53,198,119,0.25);
    @include all-trans(all,.25s,ease-in);
  }
  &_white {
    background-image: none;
    border: 1px solid $color-999;
    &:hover, &:active {
      border-color: transparent;
      color: $color-white;
      @include gradientRed;
      box-shadow:0px 0px 16px 3px rgba(245,89,98,0.25);
    }
  }
  //image button
//  &_img {
//    a {
//      background-repeat: no-repeat;
//      background-size: cover;
//      width: auto;
//      max-width: 100%;
//      padding: 0;
//      background-color: transparent !important;
//      border-radius: 0;
//      &:before {
//        content: "";
//        position: absolute;
//        top: 0;
//        left: 0;
//        right: 0;
//        bottom: 0;
//        background-color: #fff;
//        opacity: 0;
//        @include all-trans(all,.25s,ease-out);
//      }
//      &:hover {
//        background-color: transparent;
//        &:before {
//          opacity: 0.3;
//        }
//      }
//    }
//  }
//  &_off {
//    a {
//      cursor: default;
//      pointer-events: none;
//      opacity: 0.8;
//      &:before {
//        content: "";
//        position: absolute;
//        z-index: +1;
//        top: 0;
//        left: 0;
//        right: 0;
//        bottom: 0;
//        background-color: #000 !important;
//        opacity: 0.3;
//        border-radius: (50rem / $base-value);
//      }
//    }
//  }
}
//highlight button
//.hl_btn {
//  a {
//    @include all-trans(all,.25s,ease-in);
//    &:hover {
//      opacity: 0.7;
//    }
//  }
//}
//block highlight button
//.bl_btn {
//  position: relative;
//  * {
//    text-decoration: none;
//  }
//  a {
//    display: flex;
////    height: 100%;
//    color: inherit;
//    &:before {
//      content: "";
//      position: absolute;
//      z-index: +1;
//      top: 0;
//      left: 0;
//      right: 0;
//      bottom: 0;
//      background-color: #000;
//      opacity: 0;
//      @include all-trans(all,.25s,ease-out);
//      @at-root {
//        .bl_btn_w#{&} {
//          background-color: #fff;
//        }
//      }
//    }
//    &:hover {
//      background-color: transparent;
//      color: inherit;
//      &:before {
//        opacity: 0.1;
//      }
//    }
//  }
//}

