
// ---
// Typography
// ---

a {
  color: $link-color;
  @include all-trans;
  // text-decoration: underline;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  text-decoration: none;
  &:hover, &:focus, &:active {
    text-decoration: none;
    // color: $link-hover;
  }

//  &:active {
//    color: $link-active;
//  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  line-height: 1;
}

h2, .h2 {
  // @include mb(32);
  // @include em;
  // font-size: $size-h2;
  // color: $color-white;
  // line-height: 1.2;
}

h3, .h3 {
  // @include mb(24);
  // font-size: $size-h3;
  // @include em;
}

//h4, .h4 {
//  font-size: $size-h4;
//  text-align: center;
//}
//h5, .h5 {
//  font-size: $size-h5;
//  @include mb(15);
//}

p {
  // margin-top: 0;
  // &:not(last-child) {
  //   margin-bottom: 1em;
  // }
  margin: 0;

}

small {
  // font-size: $size-s;
}

.txtXs {
  font-size: $size-xs;
}
.txtMs {
  font-size: $size-ms;
}
.txtS {
  font-size: $size-s;
}
.txtL {
  font-size: $size-l;
}
.txtMl {
  font-size: $size-ml;
}
.txtXl {
  font-size: $size-xl;
}
.txtN {
  font-size: 1rem;
}

//font weight bold
.em {
  @include em;
}
//note color
.note {
  color: $color-note;
}
//font weigh normal
.fwn {
  @include fwn;
}
//italic
.italic {
  @include italic;
}
