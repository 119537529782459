// ---
// Base Site Styles
// ---

html {
  font-size: $base-font-size;
  font-weight: 500;// for 游ゴシック
  height: 100%;
  width: 100%;
  // @include bp(md) {
  //   font-size: $base-font-size-second;
  // }
}
body {
  height: 100%;
  width: 100%;
  color: $base-font-color;
  // font-size: 1em; /* currently ems cause chrome bug misinterpreting rems on*/
  font-family: $base-font-family;
  font-weight: 500;
  line-height: (44/28);
//  letter-spacing: 0.5px;
  background-color: $bg-color;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;// for iOS smooth scrolling
}

// Border box all the things:
* {
  box-sizing: border-box;
}

// ---
// General Selectors
// ---

img {
  max-width: 100%;
  vertical-align: bottom;
}
ul,dl {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
  }
}
figure {
  margin: 0;
}
dt,dd {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

