// ----
// Helper classes
// ----
.sp {
  @include bp(md) {
    display: none;
  }
}
.pc {
  display: none;
  @include bp(md) {
    display: block;
    @at-root {
      br#{&} {
        display: inline-block;
      }
    }
  }
}
.transparent {
  opacity: 0;
}
.nowrap {
  white-space: nowrap;
  display: inline-block;
}
.break {
  display: block;
}
.no_click {
  pointer-events: none;
}
.nodisp {
  visibility: hidden;
  height: 0;
  width: 0;
  margin: 0;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.cancell {
  text-decoration: line-through;
}
.clearfix {
  @include clearfix;
}
.nonclick a {
  pointer-events: none;
}
.comments {
  font-size: $size-s;
  color: $color-666;
}
/* margin, padding */
@for $i from 0 through 30 {
  ///*margin*/
  .mt#{$i * 4} { margin-top: $base-space * $i !important; }
  .mb#{$i * 4} { margin-bottom: $base-space * $i !important; }
  .ml#{$i * 4} { margin-left: $base-space * $i !important; }
  .mr#{$i * 4} { margin-right: $base-space * $i !important; }
  //*padding*/
  .pt#{$i * 4} { padding-top: $base-space * $i !important; }
  .pb#{$i * 4} { padding-bottom: $base-space * $i !important; }
  .pl#{$i * 4} { padding-left: $base-space * $i !important; }
  .pr#{$i * 4} { padding-right: $base-space * $i !important; }
}
@for $i from 1 through 5 {
  ///*margin*/
  .mt#{$i * 1}e { margin-top: 1em * $i !important; }
  .mb#{$i * 1}e { margin-bottom: 1em * $i !important; }
  .ml#{$i * 1}e { margin-left: 1em * $i !important; }
  .mr#{$i * 1}e { margin-right: 1em * $i !important; }
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.invisible {
  visibility: hidden;
  height: 0;
}

