
// ---
// Mixins
// ---

$MQs: true;

@mixin bp($point) {
  @if ($MQs) {
    // These screen sized map to the screen variables in _varibles.scss
    $bp-xs: "(min-width: #{$screen-xs})";
    $bp-sm: "(min-width: #{$screen-sm})";
    $bp-md: "(min-width: #{$screen-md})";
    $bp-lg: "(min-width: #{$screen-lg})";
    $bp-xl: "(min-width: #{$screen-xl})";
    $bp-maxxs: "(max-width: #{$screen-xs})";
    $bp-landscape: "all and (orientation: landscape)";
    $bp-portrait: "all and (orientation: portrait)";
    $bp-h568: "(max-height: 568px)";
    $bp-h620: "(min-height: 620px)";
    $bp-sp: "(max-width: #{$screen-md})";

    @if $point == xs {
      @media #{$bp-xs} { @content; }
    }
    @else if $point == sm {
      @media #{$bp-sm} { @content; }
    }
    @else if $point == md {
      @media #{$bp-md} { @content; }
    }
    @else if $point == lg {
      @media #{$bp-lg} { @content; }
    }
    @else if $point == xl {
      @media #{$bp-xl} { @content; }
    }
    @else if $point == maxmd {
      @media #{$bp-maxmd} { @content; }
    }
    @else if $point == landscape {
      @media #{$bp-landscape} { @content; }
    }
    @else if $point == portrait {
      @media #{$bp-portrait} { @content; }
    }
    @else if $point == h620 {
      @media #{$bp-h620} { @content; }
    }
    @else if $point == h568 {
      @media #{$bp-h568} { @content; }
    }
    @else if $point == sp {
      @media #{$bp-sp} { @content; }
    }

  }
}

//IE hack
@mixin ie {
  @media all and (-ms-high-contrast: none){
    @content;
  }
}

// Uppercase
@mixin upperspace() {
  letter-spacing: 1px;
  text-transform: uppercase;
}

// clearfix
@mixin clearfix() {
  &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
     clear: both;
    }
}

// %
@mixin percent( $selector, $parentPxWidth, $pxWidth ){
  $selector: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}

//margin rem
@mixin marginRem($mTop, $mRight, $mBottom, $mLeft){
  margin : floor((($mTop / $base-value) * 100rem)) / 100 floor((($mRight / $base-value) * 100rem)) / 100 floor((($mBottom / $base-value) * 100rem)) / 100 floor((($mLeft / $base-value) * 100rem)) / 100;
}

//padding rem {
@mixin paddingRem($pTop, $pRight, $pBottom, $pLeft){
  padding : floor((($pTop / $base-value) * 100rem)) / 100 floor((($pRight / $base-value) * 100rem)) / 100 floor((($pBottom / $base-value) * 100rem)) / 100 floor((($pLeft / $base-value) * 100rem)) / 100;
}

//margin-bottom
@mixin mb($size: $base-value) {
  margin-bottom: ($size / $base-value) * 1rem;
}

//font size
@mixin fs($size: $base-value) {
  font-size: $size + px;
  font-size: ($size / $base-value) * 1rem;
}

//font size (@include fz(16) -> 16px, 1.6rem)
@mixin fz($size: 16, $base: 16) {
  font-size: $size + px;
  font-size: ($size / ($base * 0.625)) + rem;
}

//font size pc
@mixin fspc($size: $base-value-second) {
  //font-size: $size + px;
  font-size: ($size / $base-value-second) * 1rem;
}

//italic
@mixin italic {
//  font-family: 'ヒラギノ明朝 Pro W6', 'Hiragino Mincho Pro', '游明朝', 'Yu Mincho', 'ＭＳ Ｐゴシック', serif;
  font-style: italic;
}

//font weight normal
@mixin fwn {
  font-weight: normal;
}

//font em
@mixin em {
  font-weight: 700;
}

//font FontAwesome
@mixin fa {
  font-family: 'FontAwesome';
}

// Altanative image
@mixin hide-txt($display: block) {
  display: $display;
  overflow: hidden;
  text-indent: 150%;
  white-space: nowrap;
  background-repeat: no-repeat;
  background-size: contain;
}

//transition
@mixin all-trans($argp:all, $args:.25s, $argf:linear) {
  transition: $argp $args $argf;
}

//flex box
@mixin flex_wrap {
  display: flex;
  -webkit-box-lines: multiple;//ios8 折り返し対応
  -moz-box-lines: multiple;
  flex-wrap:wrap;
}

//hilight button
@mixin hl-btn {
  @include all-trans(all,0.3s,ease-in);
  &:hover, &:focus, &:active {
    opacity: 0.7;
  }
}

//gradient
@mixin gradient($startColor:#37e686, $stopColor:#1d7487) {
  background: -webkit-linear-gradient(0deg, $startColor 0%, $stopColor 100%);
  background: -moz-linear-gradient(0deg, $startColor 0%, $stopColor 100%);
  background: -o-linear-gradient(0deg, $startColor 0%, $stopColor 100%);
  background: -ms-linear-gradient(0deg, $startColor 0%, $stopColor 100%);
  background: linear-gradient(90deg, $startColor 0%, $stopColor 100%);
}
@mixin gradientRed {
  background: -webkit-linear-gradient(0deg, #ed5e66 0%, #cc6989 100%);
  background: -moz-linear-gradient(0deg, #ed5e66 0%, #cc6989 100%);
  background: -o-linear-gradient(0deg, #ed5e66 0%, #cc6989 100%);
  background: -ms-linear-gradient(0deg, #ed5e66 0%, #cc6989 100%);
  background: linear-gradient(90deg, #ed5e66 0%, #cc6989 100%);
}

