// --------------------------------------------------
// Styles
// --------------------------------------------------
html {
  overflow: auto;

  @include bp(sp) {
    overflow: initial;
  }
}

body {
  overflow-x: hidden;
  color: #333;
  background-color: #FFF;
  @import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  min-width: 1280px;

  @include bp(sp) {
    min-width: auto;
  }
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a {
  display: inline-block;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
}

.container {
  margin: 0 auto;
  width: 100%;

  @include bp(sp) {
    width: 100%;
  }
}

.mv {
  width: 100%;
  height: 715px;
  background: url(../images/bg_mv_pc.png) no-repeat;
  background-size: cover;

  @include bp(sp) {
    padding-top: 85px;
    height: auto;
    background-image: url(../images/bg_mv_sp.png);
    background-size: cover;
  }

  .header {
    position: relative;

    @include bp(sp) {
      /* for SP menu (Fixed) */
      position: fixed;
      top: 0;
      left: 0;
      z-index: 4;
      width: 100%;
      transition: .3s;
    }

    &.is-animation {
      @include bp(sp) {
        /* for SP menu (Fixed) */
        height: 70px;
        background: #FFF;

        .toggle-btn {
          /* for SP menu (Fixed) */
          top: 15px;

          span {
            @include bp(sp) {
              /* for SP menu (Fixed) */
              border-bottom: solid 2px #000;
            }
          }
        }
      }

      .header-logo {
        @include bp(sp) {
          /* for SP menu (Fixed) */
          width: 75px;
        }

        img {
          @include bp(sp) {
            /* for SP menu (Fixed) */
            margin-top: 10px;
            margin-left: 20px;
          }
        }
      }
    }
  }

  .header-logo {
    width: 99px;

    @include bp(sp) {
      width: 91px;
    }

    img {
      margin-top: 22px;
      margin-left: 90px;

      @include bp(sp) {
        margin-top: 25px;
        margin-left: 25px;
      }
    }
  }

  .header-nav {
    position: absolute;
    right: 40px;
    top: 27px;

    @include bp(sp) {
      padding: 0 0 20px 0;
      position: initial;
      background-color: #FFF;

      /* for SP menu */
      position: fixed;
      z-index: 3;
      top: 0;
      left: 0;
      background: #fff;
      color: #000;
      text-align: center;
      width: 100%;
      transform: translateY(-100%);
      transition: all 0.6s;
    }

    &.active {
      @include bp(sp) {
        /* for SP menu */
        padding-top: 60px;
        transform: translateY(0%);
      }
    }

    ul {

      @include bp(sp) {
        /* for SP menu */
        width: 100%;
        margin: 0 auto;
        padding: 0;
      }

      li {
        display: inline-block;
        @include fz(15);
        font-weight: bold;

        @include bp(sp) {
          display: block;
          text-align: center;
          border-top: 1px solid #F2F2F2;
        }

        a {
          padding: 8px 23px;
          color: #FFF;

          @include bp(sp) {
            padding: 15px 0;
            width: 100%;
            color: #333;
            background-color: #FFF;
            display: block;
          }
        }

        &:last-child {
          margin-left: 20px;
          border-bottom: none;

          @include bp(sp) {
            margin: 0;
            padding: 20px 0;
            color: #FFF;
          }

           a {
            padding: 8px 38px;
            border-radius: 100px;
            background: linear-gradient(to right, #fa636f 0%, #fdae61 100%);

            @include bp(sp) {
              margin: 0 11%;
              padding: 15px 0;
              width: auto;
              color: #FFF;
            }
          }
        }
      }
    }
  }

  /* for SP menu */
  .toggle-btn {
    @include bp(sp) {
      display: block;
      position: fixed;
      right: 13px;
      top: 23px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      z-index: 3;
    }

    span {
      @include bp(sp) {
        display: block;
        position: absolute;
        width: 30px;
        border-bottom: solid 2px #FFF;
        -webkit-transition: .35s ease-in-out;
        -moz-transition: .35s ease-in-out;
        transition: .35s ease-in-out;
        left: 6px;
      }

      &:nth-child(1) {
        @include bp(sp) {
          top: 9px;
        }
      }
      &:nth-child(2) {
        @include bp(sp) {
          top: 18px;
        }
      }
      &:nth-child(3) {
        @include bp(sp) {
          top: 27px;
        }
      }
    }

    &.active span {
      @include bp(sp) {
        border-bottom: solid 2px #000;
      }
    }

    /* 最初のspanを-45度 */
    &.active span:nth-child(1) {
      @include bp(sp) {
        top: 18px;
        left: 6px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }

    /* 2番目と3番目のspanを45度 */
    &.active span:nth-child(2),
    &.active span:nth-child(3) {
      @include bp(sp) {
        top: 18px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

}

.mv-contents {
  text-align: center;

  @include bp(sp) {
    padding: 30px 0 40px 0;
  }

  .ttl-main {
    margin: 90px 0 30px 0;
    @include fz(40);
    font-weight: bold;
    color: #fff;
    line-height: 1.4;

    @include bp(sp) {
      margin: 0 0 25px;
      @include fz(24);
    }

    span {
      @include bp(sp) {
        @include fz(33);
      }
    }
  }

  .purpose-list {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;

    @include bp(sp) {
      margin-bottom: 20px;
      display: block;
    }

    li {
      padding: 0 11px;

      @include bp(sp) {
        padding: 0;
        margin-bottom: 12px;
      }
    }
  }

  .txt-main01 {
    margin-bottom: 40px;
    @include fz(35);
    font-weight: bold;
    color: #fff;
    display: inline-block;
    position: relative;

    @include bp(sp) {
      margin-bottom: 25px;
      @include fz(26);
    }

    &::before,
    &::after {
      content: '';
      display: block;
      width: 23px;
      height: 42px;
      background: url(../images/slash_line01.svg);
      position: absolute;

      @include bp(sp) {
        width: 17px;
        height: 30px;
      }
    }
    &::before {
      left: -38px;
      top: 12px;

      @include bp(sp) {
        left: -23px;
        top: 12px;
      }
    }
    &::after {
      transform: scaleX(-1);
      right: -38px;
      top: 12px;

      @include bp(sp) {
        right: -23px;
        top: 12px;
      }
    }
  }

  .contact-list {
    display: flex;
    justify-content: center;

    @include bp(sp) {
      display: block;
    }

    li {
      padding: 0 9px;

      @include bp(sp) {
        padding: 0;
        margin-bottom: 20px;
      }
    }
  }
}

.base-sec {
  .inner-wrap {
    margin: 0 auto;
    width: 1280px;

    @include bp(sp) {
      width: 100%;
    }
  }

  .title-main {
    margin: 0 0 10px;
    @include fz(22);
    font-weight: bold;
    color: #FB696E;

    @include bp(sp) {
      @include fz(14);
    }
  }
  .title-sub {
    @include fz(38);
    font-weight: bold;

    @include bp(sp) {
      @include fz(20);
    }
  }

  &.problem {
    padding: 94px 0 0 140px;
    height: 635px;
    background: url(../images/img_problem01.png) no-repeat 80% 120px;

    @include bp(sp) {
      padding: 40px 20px 256px 20px;
      height: auto;
      background: none;
      position: relative;
    }

    &::after {
      @include bp(sp) {
        content: '';
        display: block;
        width: 155px;
        height: 306px;
        background: url(../images/img_problem01.png) no-repeat center top;
        background-size: contain;
        position: absolute;
        bottom: -50px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: 1;
      }
    }

    .title-main {}
    .title-sub {
      margin-bottom: 43px;

      @include bp(sp) {
        margin-bottom: 20px;
      }
    }

    .inner-wrap {
      height: 100%;
    }

    .problem-list {
      li {
        padding: 0 0 0 46px;
        margin-bottom: 20px;
        @include fz(20);
        font-weight: bold;
        position: relative;

        @include bp(sp) {
          padding-left: 25px;
          margin-bottom: 8px;
          @include fz(16);
          background-size: contain;
        }

        &::before {
          content: '';
          display: block;
          width: 26px;
          height: 26px;
          background: url(../images/icon_check01.svg) no-repeat 0 0;
          position: absolute;
          left: 0;
          top: 5px;

          @include bp(sp) {
            width: 15px;
            height: 15px;
            background-size: contain;
          }
        }
      }
    }
  }

  &.about {
    padding: 62px 0 100px;
    color: #FFF;
    text-align: center;
    // height: 747px;
    background: linear-gradient(135deg, #fa636f 15%, #fdae61 115%);

    @include bp(sp) {
      padding: 40px 20px 40px 20px;
      position: relative;
      z-index: 2;
    }

    .title-main {
      color: rgba(253, 253, 253, 0.59);
    }
    .title-sub {
      margin-bottom: 43px;
      color: #FDFDFD;

      @include bp(sp) {
        margin-bottom: 20px;
      }
    }

    .txt-01 {
      margin-bottom: 20px;
      @include fz(30);
      font-weight: bold;
      color: #FDFDFD;

      @include bp(sp) {
        @include fz(18);
      }
    }
    .img-01 {
      margin-bottom: 10px;

      img {
        @include bp(sp) {
          width: 70%;
        }
      }
    }
    .txt-02 {
      margin-bottom: 30px;
      @include fz(25);
      font-weight: bold;

      @include bp(sp) {
        @include fz(17);
      }
    }
    .txt-03 {
      margin-bottom: 50px;
      @include fz(20);
      color: #FDFDFD;
      line-height: 2.0;

      @include bp(sp) {
        margin-bottom: 20px;
        @include fz(13);
      }
    }
  }

  &.wecando {
    padding: 80px 0 130px;
    text-align: center;

    @include bp(sp) {
      padding: 40px 20px 40px;
    }

    .title-main {}
    .title-sub {
      margin-bottom: 60px;

      @include bp(sp) {
        margin-bottom: 30px;
      }
    }

    .wecando-list {
      margin: 0 auto;
      width: 1050px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include bp(sp) {
        width: 100%;
      }

      li {
        padding: 0 13px 36px;

        @include bp(sp) {
          padding: 0 5px 10px;
          width: 45%;
        }
        
        img {
          box-shadow: 5px 9px 16px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  &.contact {
    padding: 50px 0 70px;
    text-align: center;
    color: #FFF;
    background: linear-gradient(115deg, #fa636f 20%, #fdae61 120%);

    @include bp(sp) {
      padding: 30px 20px 20px;
    }

    .title-contact {
      margin-bottom: 35px;
      @include fz(28);
      font-weight: bold;

      @include bp(sp) {
        margin-bottom: 25px;
        @include fz(18);
      }
    }

    .contact-list {
      display: flex;
      justify-content: center;

      @include bp(sp) {
        display: block;
      }

      li {
        margin: 0 11px;

        @include bp(sp) {
          margin: 0 0 20px;
          padding: 0 13%;
        }
      }
    }
  }

  &.keypoint {
    padding: 100px 0 120px 0;
    text-align: center;
    background-color: #F9F9F9;

    @include bp(sp) {
      padding: 40px 20px 40px 20px;
    }

    .title-main {}
    .title-sub {
      margin-bottom: 50px;

      @include bp(sp) {
        margin-bottom: 30px;
      }
    }

    .txt-01 {
      margin-bottom: 50px;
      @include fz(35);
      font-weight: bold;
      background: linear-gradient( to right,  #fa636f 0%, #fdae61 100%);
      -webkit-background-clip: text;
      color: transparent;

      @include bp(sp) {
        margin-bottom: 30px;
        @include fz(20);
      }
    }

    .txt-02 {
      margin-right: 155px;
      margin-bottom: 50px;
      @include fz(12);
      text-align: right;

      @include bp(sp) {
        margin-right: 0;
        margin-bottom: 40px;
        @include fz(10);
      }
    }

    .keypoint-list {
      margin-bottom: 24px;
      display: flex;
      justify-content: center;

      @include bp(sp) {
        display: block;
      }

      li {
        margin-left: 28px;

        @include bp(sp) {
          margin-left: 0;
          margin-bottom: 20px;
        }

        &::first-child {
          margin-left: 0;
        }

        img {
          box-shadow: 5px 9px 16px rgba(0, 0, 0, 0.05);
        }
      }
    }

    .attention {
      padding: 0 0 0 180px;
      text-align: left;
      background: url(../images/img_keypoint03.svg) no-repeat 785px 50px;

      @include bp(sp) {
        padding: 0;
      }

      .title-main {}
      .title-sub {
        @include fz(24);
      }

      .txt-03 {
        @include fz(18);

        @include bp(sp) {
          @include fz(14);
        }
      }
    }
  }

  &.superiority {
    padding: 90px 0 165px 0;
    text-align: center;

    @include bp(sp) {
      padding: 40px 20px;
    }

    .title-main {}
    .title-sub {
      margin-bottom: 55px;

      @include bp(sp) {
        margin-bottom: 40px;
      }
    }

    .point-area {
      margin: 0 auto 95px auto;
      padding: 47px 0 67px 0;
      width: 990px;
      position: relative;
      background: #fff;
      border: 2px solid #f0f0f0;
      box-shadow: 5px 9px 16px rgba(0, 0, 0, 0.05);

      @include bp(sp) {
        margin-bottom: 50px;
        padding: 30px 20px 30px 20px;
        width: 100%;
      }

      &.last {
        margin-bottom: 0;
      }

      .ttl-img {
        position: absolute;
        top: -25px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);

        @include bp(sp) {
          width: 130px;
          top: -17px;
        }
      }

      .ttl {
        margin-bottom: 40px;
        @include fz(30);

        @include bp(sp) {
          margin-bottom: 30px;
          @include fz(18);
        }
      }

      .point01-list {
        display: flex;
        justify-content: center;

        @include bp(sp) {
          flex-wrap: wrap;
        }

        li {
          padding: 0 30px;

          @include bp(sp) {
            margin-bottom: 20px;
            padding: 0 10px 10px;
            height: 110px;
          }

          &:nth-child(2) {

            @include bp(sp) {
              width: 132px;
            }
          }

          img {
            @include bp(sp) {
              max-height: 100%;
            }
          }
        }
      }

      .dl-wrap {
        .point02-dl {
          margin: 0 auto 0 auto;
          padding: 25px 0 30px;
          width: 820px;
          text-align: left;
          border-top: 1px solid #BABABA;
          display: flex;
          align-items: center;

          @include bp(sp) {
            margin: 0;
            padding: 25px 0 20px;
            width: auto;
            display: block;
          }

          &:first-of-type {
            border-top: none;

            @include bp(sp) {
              padding-top: 0;
            }
          }

          dt {
            padding-left: 33px;
            width: 410px;

            @include bp(sp) {
              margin-bottom: 20px;
              padding-left: 0px;
              width: 100%;
              height: 55px;
            }

            img {

              @include bp(sp) {
                max-height: 100%;
              }
            }
          }

          dd {
            padding-left: 80px;
            @include fz(18);

            @include bp(sp) {
              @include fz(14);
            }
          }
        }
      }

      .point03-wrap {
        margin: 0 40px 0 95px;
        padding: 0 0 20px;
        text-align: left;
        display: flex;
        background: url(../images/img_superiority11.svg) no-repeat right bottom;

        @include bp(sp) {
          margin: 0;
          padding: 0 0 100px 0;
          text-align: center;
          background-size: auto 80px;
          background-position: center bottom;
          display: block;
        }

        .txt-01 {
          margin-left: 55px;
          @include fz(18);
          line-height: 1.8;

          @include bp(sp) {
            margin-top: 20px;
            margin-left: 20px;
            @include fz(14);
          }
        }
      }
    }
  }

  &.management {
    margin: 0 0 110px 0;

    @include bp(sp) {
      margin: 0;
      padding: 40px 20px;
    }

    .title-main {}
    .title-sub {
      margin-bottom: 45px;

      @include bp(sp) {
        margin-bottom: 30px;
      }
    }

    .management-wrap {
      padding-left: 140px;
      display: flex;

      @include bp(sp) {
        padding-left: 0;
        display: block;
      }

      .management-list {
        width: 530px;

        @include bp(sp) {
          width: 100%;
        }

        li {
          padding: 25px 0;
          border-top: 1px solid #BABABA;

          &:first-of-type {
            border-top: none;

            @include bp(sp) {
              padding-top: 0;
            }
          }

          &:nth-of-type(1) {
            img {
              width: 75%;// 393px
            }
          }
          &:nth-of-type(2) {
            img {
              width: 75%;// 392px
            }
          }
          &:nth-of-type(3) {
            img {
              width: 100%;// 525px
            }
          }
        }
      }

      .img-01 {
        margin: 0 0 0 40px;

        @include bp(sp) {
          margin: 0;
        }

        img {
          margin-top: 90px;

          @include bp(sp) {
            margin-top: 40px;
          }
        }
      }
    }
  }

  &.service {
    padding: 90px 0 130px 0;
    text-align: center;

    @include bp(sp) {
      padding: 40px 20px;
    }

    .title-main {}
    .title-sub {
      margin-bottom: 95px;

      @include bp(sp) {
        margin-bottom: 40px;
      }
    }

    .img-01 {
      display: inline-block;
    }
  }

  &.voice {
    padding: 100px 0 87px 0;
    background: #F9F9F9 url(../images/img_voice01.png) no-repeat right bottom;
    text-align: center;

    @include bp(sp) {
      padding: 40px 20px 10px;
      background-size: 85%;
    }

    .title-main {}
    .title-sub {
      margin-bottom: 70px;
    }

    .voice-list {
      text-align: left;
      display: flex;
      justify-content: center;

      @include bp(sp) {
        display: block;
      }

      .point-area {
        margin: 0 14px;
        padding: 60px 45px 100px 45px;
        width: 485px;
        position: relative;
        background: #fff;
        border: 2px solid #f0f0f0;
        box-shadow: 5px 9px 16px rgba(0, 0, 0, 0.05);

        @include bp(sp) {
          margin: 0 0 50px 0;
          padding: 40px 20px;
          width: 100%;
        }

        .ttl-img {
          text-align: center;
          position: absolute;
          top: -25px;
          left: 0;
          right: 0;
        }

        .ttl {
          margin: 0 0 25px;
          @include fz(34);
          line-height: 1.5;

          @include bp(sp) {
            @include fz(20);
          }
        }
        .txt-01 {
          margin-bottom: 30px;
          @include fz(18);
          line-height: 1.8;

          @include bp(sp) {
            @include fz(14);
          }
        }
        .txt-bottom {
          @include fz(18);
          color: #484848;
          font-weight: 400;
          position: absolute;
          bottom: 55px;

          @include bp(sp) {
            @include fz(14);
            position: initial;
          }
        }

      }
    }
  }

  &.support {
    padding: 100px 0 75px 0;

    @include bp(sp) {
      padding: 40px 20px;
    }

    .content-wrap {
      margin: 0 0 0 134px;
      width: 585px;

      @include bp(sp) {
        margin: 0;
        width: 100%;
      }
    }

    .title-main {}
    .title-sub {
      margin-bottom: 55px;

      @include bp(sp) {
        margin-bottom: 30px;
      }
    }

    .support-wrap {
      display: flex;
      justify-content: space-between;

      @include bp(sp) {
        display: block;
      }
    }

    .support-dl {
      dt {
        padding: 30px 0;
        border-top: 1px solid #BABABA;

        @include bp(sp) {
          padding: 20px 0;
          width: 100%;
        }

        &:first-of-type {
          padding-top: 0;
          border-top: none;
        }

        &:nth-of-type(1) {
          img {
            @include bp(sp) {
              max-width: 58%;// 324px
            }
          }
        }
        &:nth-of-type(2) {
          @include bp(sp) {
            img {
              max-width: 76%;// 423px
            }
          }
        }
        &:nth-of-type(3) {
          @include bp(sp) {
            img {
              max-width: 100%;// 554px
            }
          }
        }
      }
      dd {
        padding: 0 30px 35px 62px;
        @include fz(18);
        line-height: 1.8;

        @include bp(sp) {
          padding: 0 0 20px 11%;
          @include fz(14);
        }
      }
    }

    .img-01 {
      margin: 40px 0 0 0;

      @include bp(sp) {
        margin-top: 20px;
      }
    }
  }
  
  &.price {
    padding: 100px 0 115px 0;
    background: #F9F9F9;
    text-align: center;

    @include bp(sp) {
      padding: 40px 20px;
    }

    .title-main {}
    .title-sub {
      margin-bottom: 90px;

      @include bp(sp) {
        margin-bottom: 40px;
      }
    }

    .price-wrap {
      margin: 0 auto;
      width: 1010px;
      background: #fff;
      border: 2px solid #f0f0f0;
      box-shadow: 5px 9px 16px rgba(0, 0, 0, 0.05);

      @include bp(sp) {
        width: 100%;
      }
    }

    .price-table {
      margin: 0 auto;
      width: 886px;

      @include bp(sp) {
        width: 100%;
      }

      thead {
        th {
          height: 24px;

          &.ttl-img {
            width: 300px;
            position: relative;
            
            @include bp(sp) {
              width: auto;
            }

            img {
              position: absolute;
              top: -45px;
              left: 50%;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);

              @include bp(sp) {
                width: 100px;
                top: -14px;
              }
            }
          }
        }
      }

      tbody {
        tr {
          border-top: 1px solid #BABABA;

          &:first-of-type {
            border-top: none;
          }
        }

        th {
          padding: 30px 0 30px 25px;
          text-align: left;
          @include fz(24);
          font-weight: bold;
          line-height: 1.3;

          @include bp(sp) {
            padding: 15px 0 15px 10px;
            @include fz(12);
          }

          span {
            @include fz(18);
            font-weight: normal;

            @include bp(sp) {
              @include fz(11);
            }
          }
        }

        td {
          @include fz(28);
          font-weight: bold;
          color: #FA646F;

          @include bp(sp) {
            @include fz(16);
          }

          img {
            vertical-align: middle;
          }

          .yen {
            @include fz(33);

            @include bp(sp) {
              @include fz(15);
            }
          }
          .price {
            @include fz(38);

            @include bp(sp) {
              @include fz(17);
            }
          }
          .mark {
            margin-left: 10px;
            @include fz(33);

            @include bp(sp) {
              margin-left: 4px;
              @include fz(15);
            }
          }
          .fixed-cost {
            @include fz(32);

            @include bp(sp) {
              @include fz(14);
            }
          }

          .icon_line01 {
            @include bp(sp) {
              width: 15px;
            }
          }

          .icon_check02 {
            @include bp(sp) {
              width: 22px;
            }
          }

          &.btn-contact {
            padding: 35px 0 56px;

            @include bp(sp) {
              padding: 20px 10px 30px;
            }

            img {
              @include bp(sp) {
                width: 120px;
              }
            }
          }
        }
      }
    }
  }

  &.qanda {
    padding: 90px 0 45px 0;
    text-align: center;

    @include bp(sp) {
      padding: 40px 20px;
    }

    .title-main {}
    .title-sub {
      margin-bottom: 45px;
    }

    .qanda-dl {
      margin: 0 auto 40px auto;
      padding: 32px 50px 32px 50px;
      width: 1010px;
      text-align: left;
      background: #fff;
      border: 2px solid #f0f0f0;
      box-shadow: 5px 9px 16px rgba(0, 0, 0, 0.05);

      @include bp(sp) {
        margin-bottom: 30px;
        padding: 20px;
        width: 100%;
      }

      dt {
        margin-bottom: 25px;
        @include fz(30);
        font-weight: bold;

        @include bp(sp) {
          margin-bottom: 15px;
          @include fz(20);
        }

        span {
          margin-right: 10px;
          @include fz(35);
          color: #FB696E;

          @include bp(sp) {
            @include fz(22);
          }
        }
      }
      dd {
        @include fz(18);
        line-height: 1.8;

        @include bp(sp) {
          @include fz(14);
        }
      }
    }
  }

}// end .base-sec

.footer {
  .inner {
    margin: 0 auto;
    padding: 45px 72px 56px 64px;
    width: 1280px;
    position: relative;

    @include bp(sp) {
      padding: 0;
      width: 100%;
      text-align: center;
    }
  }

  .copyright {
    @include fz(14);
    font-weight: 400;

    @include bp(sp) {
      padding: 20px 10px;
      @include fz(12);
    }

    small {
      font-size: 100%;
    }
  }

  .footer-nav {
    ul {
      position: absolute;
      right: 72px;
      top: 45px;

      @include bp(sp) {
        position: initial;
      }

      li {
        margin-left: 45px;
        @include fz(14);
        font-weight: normal;
        display: inline-block;

        @include bp(sp) {
          margin: 0;
          display: block;
          border-bottom: 1px solid #F2F2F2;
        }

        a {
          @include bp(sp) {
            padding: 10px;
            display: block;
          }
        }
      }
    }
  }
}








